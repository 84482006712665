<template>
  <div class="c-container c-container--small">
    <Tile class="mb-10">
      <div class="flex p-8 w-full border-bottom--base">
        <div class="flex flex-col">
          <div class="flex gap-4 content-center items-center">
            <Title size="small" title="Stream overlay" class="text-blue-lighter" />

            <span class="ml-auto text-sm text-signal-red"> Required </span>
          </div>

          <span v-if="checkOverlayLength" class="flex items-center mt-2 w-full paragraph">
            <Icon :width="10" class="mr-2" :height="10" icon="check" color="#5ece8a" />
            Overlay added
          </span>
          <span v-else class="mt-2 paragraph">No overlay added</span>
        </div>

        <Button
          kind="secondary"
          class="ml-auto"
          url="/create/campaign/overlay"
          :label="checkOverlayLength ? 'Edit overlay' : 'Add overlay'"
        />
      </div>

      <!-- Chatbot -->
      <div class="flex flex-col p-8 border-bottom--base">
        <div class="flex items-center w-full">
          <Title size="medium" title="Chatbot" class="text-blue-lighter" />

          <InputToggle class="ml-auto" label="Enable chatbot" @toggle="toggleChatbot" />
        </div>

        <span v-if="!showChatbot" class="mt-2 paragraph">Chatbot not enabled</span>

        <div v-else class="flex flex-col mt-8">
          <InputText
            label="Chatbot message"
            class="mb-6"
            :border="true"
            :value="campaign.tools.chatbot.text"
            @onChange="setChatbotMessage"
          />

          <InputText
            class="mb-6"
            label="URL destination"
            :border="true"
            type="url"
            :value="campaign.tools.chatbot.link"
            :invalid-text="validChatBotLink ? '' : 'Not a valid URL'"
            @onChange="setChatbotLink"
          />

          <div class="max-w-full">
            <Title size="medium" title="URL codes" class="mb-2" />

            <div
              v-if="campaign.tools.chatbot.utmCodes.length"
              class="grid mb-2"
              style="grid-template-columns: 1fr 1fr 2fr 0.5fr"
            >
              <Title size="small" title="Type" />

              <Title size="small" title="Parameter" />

              <Title size="small" title="Example" />
            </div>

            <div
              v-for="(utmCode, utmCodeIndex) of campaign.tools.chatbot.utmCodes"
              :key="utmCodeIndex"
              class="grid mb-2"
              style="grid-template-columns: 1fr 1fr 2fr 0.5fr"
            >
              <p class="text-sm">{{ utmCode.type }}</p>

              <p class="text-sm">
                {{ utmCode.parameter }}
              </p>

              <p class="text-sm">
                {{
                  `?${utmCode.parameter}=${
                    utmCode.type === "static" ? encodeURIComponent(utmCode.value) : `{${utmCode.type.toUpperCase()}\}`
                  }`
                }}
              </p>

              <Button kind="link" class="ml-auto" @clicked="removeUTMCode(utmCodeIndex)">
                <Icon icon="trashcan" height="20px" class="fill-white-default" color="white" />
              </Button>
            </div>

            <Button label="Add new URL code" :capitalize="false" kind="link" class="mt-4" @clicked="openUTMCodeModal" />
          </div>
        </div>
      </div>
    </Tile>

    <div class="flex justify-center items-center w-full">
      <Button class="mr-10" :url="'/create/campaign/streamers'" kind="link" label="Previous step" />
      <Button kind="primary" label="Complete setup" @clicked="saveCampaign" />
    </div>

    <div
      v-if="showUTMCodeModal"
      style="background-color: rgba(0, 0, 0, 0.4); z-index: 1058"
      class="flex fixed top-0 left-0 place-content-center place-items-center w-screen h-screen rounded"
      @click="resetUTMCodeModal"
    >
      <div class="p-8 w-full max-w-xl h-auto" @click.stop>
        <Tile class="p-8 w-full h-auto" style="z-index: 1059">
          <div class="flex">
            <Title :title="typeof editItemIndex === 'number' ? 'Edit URL code' : 'Add URL code'" size="large" />

            <button class="ml-auto" @click="resetUTMCodeModal">
              <Icon icon="close" height="20px" />
            </button>
          </div>

          <!-- Item type -->
          <div class="flex items-center mt-4">
            <p class="font-medium text-blue-lighter">Type</p>

            <Dropdown
              :border="true"
              :items="getUTMTypes"
              class="ml-auto capitalize"
              :current-item="editItem.type"
              :index="getUTMTypes.indexOf(editItem.type)"
              @change="changeItemType"
            />
          </div>

          <!-- Parameter -->
          <InputText
            label="Parameter"
            class="mt-4"
            type="text"
            :required="true"
            :value="editItem.parameter"
            @onChange="setItemParameter"
          />

          <!-- Value -->
          <InputText
            v-if="editItem.type === 'static'"
            label="Value"
            class="mt-4"
            type="text"
            :required="true"
            :value="editItem.value"
            @onChange="setItemValue"
          />

          <!-- Save -->
          <div class="flex gap-4 mt-4 mr-auto ml-auto">
            <Button class="text-white-default" @clicked="saveUTMCode">
              <Icon icon="save" height="20px" class="mr-2 fill-white-default" color="white" />

              <span class="text-white-default"> Save </span>
            </Button>

            <!-- Delete -->
            <Button
              v-if="editItemIndex !== -1 && typeof editItemIndex === 'number'"
              class="px-6 h-10 text-white-default bg-signal-red rounded"
              kind="link"
              @clicked="removeUTMCode"
            >
              <Icon icon="trashcan" height="20px" class="mr-2 fill-white-default" color="white" />

              <span class="text-white-default"> Delete </span>
            </Button>
          </div>
        </Tile>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import axios from "axios";
import { Icon, Button, Title, Tile } from "cavea-design-system";
import log from "@/lib/log";

export default {
  name: "CreateCampaignStreamers",

  metaInfo: {
    title: "Create Campaign Content",
  },

  components: {
    Icon,
    Title,
    Button,
    InputToggle: () => import("@/components/inputs/InputToggle"),
    InputText: () => import("@/components/inputs/InputText"),
    Tile,
    Dropdown: () => import("@/components/Dropdown"),
  },

  data() {
    return {
      campaign: null,
      showChatbot: false,

      showUTMCodeModal: false,
      editItemIndex: null,
      editItem: null,
    };
  },

  computed: {
    ...mapGetters(["getCampaign", "getOrganization"]),

    /**
     * @returns {number}
     */
    checkOverlayLength() {
      return this.campaign?.tools?.overlay?.carousel?.imageArr?.length ?? 0;
    },

    /**
     * @returns {boolean}
     */
    validChatBotLink() {
      if (!this.campaign?.tools?.chatbot?.link?.length) return false;

      try {
        const url = new URL(this.campaign.tools.chatbot.link);

        return !!url?.href;
      } catch (_) {
        return false;
      }
    },

    /**
     * @returns {{ label: string, id: string}[]}
     */
    getUTMTypes() {
      return [
        { label: "static", id: "static" },
        { label: "username", id: "username" },
      ];
    },
  },

  watch: {
    campaign: {
      deep: true,
      handler() {
        this.setCampaign(this.campaign);
      },
    },
  },

  created() {
    this.campaign = this.getCampaign;

    if (this.campaign?.tools?.chatbot?.text || this.campaign?.tools?.chatbot?.link) {
      this.showChatbot = true;
    }

    if (!this.campaign?.tools?.chatbot?.utmCodes) {
      this.campaign.tools.chatbot.utmCodes = [];
    }
  },

  methods: {
    ...mapMutations(["setCampaign"]),

    ...mapActions(["fetchCampaigns"]),

    /**
     * @summary toggle chatbot on/off
     */
    toggleChatbot() {
      this.showChatbot = !this.showChatbot;
    },

    /**
     * @summary commit campaign data to state
     */
    async saveCampaign() {
      if (!this.checkOverlayLength) {
        this.$swal({
          icon: "error",
          title: "Missing overlay!",
          text: "Please add an overlay creative.",
        });
        return;
      }

      if (this.campaign._id != null) {
        // Update existing campaign
        const URL = `${process.env.VUE_APP_API_URL}/ad-campaign/${this.campaign._id}`;

        await axios
          .put(URL, this.campaign)
          .then(() => {
            this.$router.push("/create/campaign/completed");
          })
          .catch((err) => {
            console.error("saveCampaign 1", err);

            this.$swal({
              icon: "error",
              title: "Oops, something went wrong!",
              text: "Please try again later",
            });
          });
      } else {
        // Create new campaign
        const URL = `${process.env.VUE_APP_API_URL}/ad-campaign/${this.getOrganization._id}`;

        await axios
          .post(URL, this.campaign)
          .then(() => {
            this.$router.push("/create/campaign/completed");
          })
          .catch((err) => {
            console.error("saveCampaign 2", err);

            this.$swal({
              icon: "error",
              title: "Oops, something went wrong!",
              text: "Please try again later",
            });
          });
      }

      this.fetchCampaigns();
    },

    /**
     * @summary set chatbot message
     * @param {string} value
     */
    setChatbotMessage(value) {
      this.campaign.tools.chatbot.text = value;
    },

    /**
     * @summary set chatbot link
     * @param {string} value
     */
    setChatbotLink(value) {
      if (value.indexOf("https://") === -1 && value.indexOf("http://")) {
        value = `https://${value}`;
      }

      try {
        const url = new URL(value);

        const oldURL = new URL(this.campaign.tools.chatbot.link);

        if (url.host !== oldURL.host) {
          const { searchParams } = this.extractQueryParams(value);

          this.campaign.tools.chatbot.utmCodes = searchParams ?? [];
        }
      } catch (error) {
        console.error("Error creating new URL", error);
      }

      this.campaign.tools.chatbot.link = value;
    },

    /**
     * @param {string} value
     */
    setItemParameter(value) {
      this.editItem.parameter = value;
    },

    /**
     * @param {string} value
     */
    setItemValue(value) {
      this.editItem.value = value;
    },

    openUTMCodeModal(index = null, type = null) {
      if (index !== null && type) {
        this.editItemIndex = index;
        this.editItem = this.campaign.tools.chatbot.utmCodes[index];
      } else {
        this.editItem = {
          parameter: "",
          value: "",
          type: "static",
        };
      }

      this.showUTMCodeModal = true;
    },

    resetUTMCodeModal() {
      this.showUTMCodeModal = false;
      this.editItem = null;
      this.editItemIndex = null;
    },

    changeItemType(e) {
      log({ msg: "changeItemType", e });

      if (e?.item?.label === "static") {
        this.editItem.type = "static";
        this.editItem.value = "";
      } else if (e?.item?.label === "username") {
        this.editItem.type = "username";
        this.editItem.value = undefined;
      }
    },

    saveUTMCode() {
      if (!this.editItem?.parameter || (this.editItem?.type === "static" && !this.editItem.value)) return;

      if (this.editItemIndex !== null) {
        this.campaign.tools.chatbot.utmCodes[this.editItemIndex] = this.editItem;
      } else {
        this.campaign.tools.chatbot.utmCodes.push(this.editItem);
      }

      this.resetUTMCodeModal();
    },

    async removeUTMCode(index) {
      log({ fn: "removeUTMCode", index });

      if (index !== null && index > -1) {
        const confirmation = await this.$swal({
          title: `Are you sure you want to remove this URL code?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#c13859",
          confirmButtonText: `Yes`,
        });

        if (confirmation.isConfirmed) {
          this.campaign.tools.chatbot.utmCodes.splice(index, 1);
        }
      }
    },

    extractQueryParams(url) {
      if (url.indexOf("https://") === -1 && url.indexOf("http://")) {
        url = `https://${url}`;
      }

      const searchParams = [];

      try {
        const URI = new URL(url);
        for (const parameter of URI.searchParams.keys()) {
          searchParams.push({
            type: "static",
            parameter,
            value: URI.searchParams.get(parameter),
          });
        }
      } catch (_) {
        //
      }

      return {
        url,
        searchParams,
      };
    },

    insertQueryParams(url, queryParams) {
      if (url.indexOf("https://") === -1 && url.indexOf("http://")) {
        url = `https://${url}`;
      }

      try {
        const URI = new URL(url);

        for (const param of queryParams) {
          if (param.type === "static") {
            URI.append(param.parameter, param.value);
          }
        }

        return URI.href;
      } catch (_) {
        return url;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/helpers";
</style>
